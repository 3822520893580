import React from "react";
import { LoggedInUser } from "@mksap/types/loggedInUser";
import { WebLoginForm } from "./WebLoginForm";
import { Card, Container } from "../ui/MksapReactBootstrap";
import imagePath from "@mksap/utils/imagePath";
import { LandingPageCarousel } from "./LandingPageCarousel";

interface LandingPageProps {
	onLogIn(userData: LoggedInUser): void;
}

// TODO: just absorb WebLoginForm into this component? seems kind of pointless to separate it?
export const LandingPage: React.FC<LandingPageProps> = ({ onLogIn }) => {
	const slides = [
		{
			image: imagePath("placeholder-screenshot-syllabus.png"),
			text: (
				<>
					<h5>Highly structured and expert-created Syllabus</h5>
					<p>
						Sixteen evidence-based subspecialty content areas with information
						in the core of internal medicine.
					</p>
				</>
			),
		},
		{
			image: imagePath("placeholder-screenshot-questionbank.png"),
			text: (
				<>
					<h5>Test Your Knowledge</h5>
					<p>
						Gauge your knowledge and prepare for exam day with more than 1200
						all-new multiple-choice questions.
					</p>
				</>
			),
		},
		{
			image: imagePath("placeholder-screenshot-learningplan.png"),
			text: (
				<>
					<h5>Personalized Learning Plan</h5>
					<p>
						Target topics to deepen your understanding with related content and
						learning links.
					</p>
				</>
			),
		},
	];

	return (
		<div className="bg-primary bg-gradient center vh-100">
			<div className="web-login-form top-0 start-0 bottom-0 end-0">
				<Container fluid="xl" className="top-50 start-50">
					<div className="row justify-content-center m-md-5">
						<div className="col-md-5 col--form">
							<Card className="h-100 d-flex flex-column pt-4 pt-md-0">
								<img
									src={imagePath("acp-mksap-logo-horiz-4c-tagline.svg")}
									className="card-img-top pt-4 px-3"
									alt="ACP MKSAP"
								/>
								<Card.Body>
									<WebLoginForm onLogIn={onLogIn} />
								</Card.Body>
							</Card>
						</div>
						<div className="col col--slider h-100 px-4 py-5 p-md-0 m-4 m-md-0">
							<LandingPageCarousel slides={slides} />
						</div>
					</div>
				</Container>
			</div>
		</div>
	);
};
